//Text
$text-color:#2a2a2a;
$text-color-light:lighten($text-color,40%);
$green-link:#479239;
$blue-link:#349ef7;
$orange-link:#f59826;

$primaryLinkColor:$orange-link;
$primaryLinkColorHover:darken($primaryLinkColor,10%);

//Buttons
$orange-color:#f59826;
$blue-color:#62b1f2;
$green-color:#47cc5f;
$red-color:#bd492f;
$grey-color:#c9d0d8;

$grey-background:#f3f3f3;

$bannerIcons:$text-color;

$buttonBackground:$orange-color;
$buttonForeground:#ffffff;
$buttonBackgroundHover:darken($buttonBackground,10%);
$buttonForegroundHover:$buttonForeground;

$bannerLinks:#ffffff;
$bannerBackground:#99b98d;
$overlayBackground:rgba(31, 31, 31, 0.4);

$border-color:#737a81;
$fieldBorder:1px solid $border-color;

//Mobile Menu
$mobile-menu-background:#ffffff;
$mobile-menu-background-hover:darken($mobile-menu-background,8%);

//Grid colors
$grid-background:#ffffff;
$row-background:$grid-background;
$row-background-highlight:darken($row-background,8%);

//app colors
$appBackground:#ffffff;
$appHeadingForeground:#ffffff;
$appHeadingBackground:$text-color-light;
$appFiltersBorder:$border-color;
