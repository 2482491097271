@import '../../scss_modules/all';
@import url('https://fonts.googleapis.com/css?family=Crete+Round:400,400i');
body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 13px;
	color: $text-color;
	background: #ffffff; 
	background-repeat: repeat-y;
	background-attachment: fixed;
	padding: 0;
	margin: 0;
	overscroll-behavior-y: contain;
}
body * {
	outline:none;
}







a, a:active { 
	color: $orange-color; 
	text-decoration: none;
}

a:hover, a:focus { 
	color:darken($orange-color,5%); 
	text-decoration: none;
}
.wpthemeBanner .wpthemeBranding img {
	background-position: 0 -448px;
}
.wpthemeControlHeader {
	/*border-top: 3px solid #003366;*/
	border-top: 3px solid #f60;
}
.outerContainer {
	height: 100%;
	overflow: hidden;
}
.outerContainer::-webkit-scrollbar {
 height: 10px;
 width: 10px;
 background: #fff;
 border-left:1px solid #999;
}
.outerContainer::-webkit-scrollbar-thumb {
 background: #ccc;
 border-left:1px solid #999;
}
.outerContainer::-webkit-scrollbar-corner {
 background: #ccc;
}
 .outerContainer::-webkit-scrollbar { /* 1 */
}
.outerContainer::-webkit-scrollbar-button { /* 2 */
}
.outerContainer::-webkit-scrollbar-track { /* 3 */
}
.outerContainer::-webkit-scrollbar-track-piece { /* 4 */
}
.outerContainer::-webkit-scrollbar-thumb { /* 5 */
}
.outerContainer::-webkit-scrollbar-corner { /* 6 */
}
.outerContainer::-webkit-resizer { /* 7 */
}
p::selection {
	background-color: #676767;
	color: #fff;
}
.dj_ie .outerContainer {
	height: auto;
	overflow: hidden;
}
header.headerClass {
/*	position: relative !important; */
}
.headerBanner {
	position: relative;/*	background-color:#000;
	overflow:hidden; */
}
.headerBanner .wpthemeInner,
.mobileSearchContainer .wpthemeInner  {
	width: 1080px;
	position: relative;
}
.topBorder {
	border-top: 1px solid $border-color;
}
.overlayContainer {
	position: relative;
}
.mobileNavButton {
	display: none;
}
.shoppingCartBar {
}
.bannerContainers .slogan {
	font-size: 1.5em;
	font-weight: 700;
	color: #008c5b;
	position: absolute;
	bottom: 17px;
	left: 160px;
	/*display: none;*/
	margin-left:20px;
	    font-family: crete round;
}


/*.bannerContainers .bannerCart {
	position: relative !important;
	bottom: 0px;
	right: 0;
}*/

.bannerContainers .customerServiceHours {
	position: absolute;
	bottom: 85px;
	right: 0;
	text-align: right;
}
.bannerContainers .customerServiceHours .heading {
	font-weight: 700;
	color: #008c5b;
}
#topLevelLinksButton {
	text-align: center;
	background: rgb(0,138,191);
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwOGFiZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDc1YTMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(0,138,191,1) 0%, rgba(0,117,163,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0,138,191,1)), color-stop(100%, rgba(0,117,163,1)));
	background: -webkit-linear-gradient(top, rgba(0,138,191,1) 0%, rgba(0,117,163,1) 100%);
	background: -o-linear-gradient(top, rgba(0,138,191,1) 0%, rgba(0,117,163,1) 100%);
	background: -ms-linear-gradient(top, rgba(0,138,191,1) 0%, rgba(0,117,163,1) 100%);
	background: linear-gradient(to bottom, rgba(0,138,191,1) 0%, rgba(0,117,163,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008abf', endColorstr='#0075a3', GradientType=0 );
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #666;
}
#topLevelLinksButton button {
	background: none;
	background-color: rgba(255,255,255,.4);
	margin: 0px;
	padding: 0px;
	background-repeat: no-repeat;
	background-position: center 0px;
	width: 100px;
	height: 5px;
	border-top-width: 0px;
	border-right-width: 1px;
	border-bottom-width: 0px;
	border-left-width: 1px;
	border-top-style: none;
	border-right-style: solid;
	border-bottom-style: none;
	border-left-style: solid;
	border-top-color: #FFF;
	border-right-color: #333;
	border-bottom-color: #FFF;
	border-left-color: #333;
	cursor: pointer;
}
#topLevelLinksButton.open button .arrow {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #fff;
	display: block;
	margin: auto;
}
#topLevelLinksButton.closed button .arrow {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #fff;
	display: block;
	margin: auto;
}
.wpthemeCommonActions .wpthemeBranding {
	display: none;
}
.portletHeading {
	display: none;
}
.outerContainer .innerContainer {
}
/*
.outerContainer .innerContainer .wpthemeInner .leftCol {
	float: left;
	overflow: hidden;
	margin-right: 5px;
	width:208px;
}
.outerContainer .innerContainer .wpthemeInner .rightCol {
	float: right;
	overflow: hidden;
	margin-left: 5px;
}
.outerContainer .innerContainer .wpthemeInner .mainCol {
	clear:right;
	overflow:hidden;
}
.outerContainer .innerContainer .wpthemeInner .wptheme2Col .wpthemeCol .mainCol {
	clear:none;	
}

.outerContainer .innerContainer .wpthemeInner .equal .topSection {
	overflow:hidden;
}
.outerContainer .innerContainer .wpthemeInner .equal .middleSection {
	overflow:hidden;
}
.outerContainer .innerContainer .wpthemeInner .equal .bottomSection {
	overflow:hidden;
}
.outerContainer .innerContainer .wpthemeInner .wptheme2Col .equal .layoutTemplateLeftCol {
	width:50%;
	margin:0px;
	float:left;
}
.outerContainer .innerContainer .wpthemeInner .wptheme2Col .equal .layoutTemplateRightCol {
	width:50%;
	margin:0px;
	float:left;
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .layoutTemplateLeftCol {
	width:33.33333%;
	margin:0px;
	float:left;
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .layoutTemplateMiddleCol {
	width:33.33333%;
	margin:0px;
	float:left;
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .layoutTemplateRightCol {
	width:33.33333%;
	margin:0px;
	float:left;
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .topSection .layoutTemplateLeftCol .component-container {
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .topSection .layoutTemplateMiddleCol .component-container {
	margin-left:5px;
}
.outerContainer .innerContainer .wpthemeInner .wptheme3Col .equal .topSection .layoutTemplateRightCol .component-container {
	margin-left:5px;
}
*/
.appContainer .innerAppContainer {
	width: 1080px;
	margin: auto;
}
.right {
	text-align: right;
}
.center {
	text-align: center;
}
.justify {
	text-align: justify;
}
.wpthemeCol-1 {
	width: 100%;
	margin: 0px;
}

footer {
	clear: both;
	overflow: hidden;
}
.shadow {
}
/*
.insetShadow {
	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}
*/
.dijitTitlePaneTitle h2 {
	font-size: inherit;
	margin: 0px;
	padding: 0px;
}
.wpthemeMainContent {
	position: relative;
	overflow: hidden;
}
.appContainer {
	padding-top: 10px;
}
.appContainer.orderingPortletView, .appContainer.categoryListing, .appContainer.productItemListing, .appContainer.itemDetail, .appContainer.manufacturerItems, .appContainer.searchResults, .appContainer.orderFromHistory {
	padding-top: 0;
}
.appContainer .portletHeading {
	display: none;
}
.wpthemeControl {
	margin: 0px;
}
.wpthemeLayoutContainers {
/*	padding:0px!important; */
}
.topNavBar ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}
.topNavBar ul li:hover a, .topNavBar ul li.lotusSelected a {
	background: rgba(255, 255, 255, 0.2);/*	color: #fff;
	border-left-color: rgba(255, 255, 255, 0.2);
	border-right-color: rgba(255, 255, 255, 0.2);
	text-shadow: 0 1px 2px #c1c1c1;
	box-shadow: 1px 0 0 #999, -1px 0 0 #999, 0px 0 0 #999 inset,0px 0 0 #999 inset; */
}
.topNavBar ul ul li {
	border: none;
}
.topNavBar .modelHasChildren .modelHasChildrenIcon, .topNavBar .modelHasChildrenIcon {
	display: none;
}
.topNavBar li .subMenu-1 {
	display: none;
	z-index: 500;
	position: absolute;
	background: rgb(229,229,229);
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1ZTVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229,229,229,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff', GradientType=0 );
	border: 1px solid #999;
}
.topNavBar li:hover .subMenu-1 {
	display: inline;
}
.topNavBar li span.subMenu-1 ul {
	float: left;
}
.topNavBar li .subMenu-1 li {
	float: none;
	background-image: none;
	padding-left: 0px;
	position: relative;
}
.topNavBar li .subMenu-1 a, .topNavBar li .subMenu-1 a:hover {
	color: #676767 !important;
	font-size: 10pt;
	background: none !important;
	padding: 0px;
	line-height: 16px;
	margin: 10px;
	margin-left: 10px;
	margin-right: 10px;
	box-shadow: none !important;
}
.topNavBar li .subMenu-1 li:hover > a {
	text-decoration: underline;
}
.topNavBar li ul.subMenu-2 {
	display: none;
	z-index: 500;
	float: left;
	position: absolute;
	top: -10px;
	left: 100%;
	background: rgb(229,229,229);
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1ZTVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229,229,229,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff', GradientType=0 );
	border: 1px solid #999;
	white-space: nowrap;
}

.topNavBar .subMenu-1 li:hover .subMenu-2 {
	display: inline;
}
.topNavBar li .subMenu-2 a:hover {
	text-decoration: underline;
}
.wpthemeHeader .wpthemeInner {
	width: auto;
}
.ibmPortalCustomizeShelf .wpthemeInner {
	width: auto;
}
.ibmPortalCustomizeShelf .utb-btn-container {
	margin-right: 10px;
}
.ibmPortalCustomizeShelf .utb-palette .lotusTabs {
	margin-left: 10px;
}
.utb-container .lotusPaletteNav {
	margin-left: 5px;
}
.utb-overview {
	padding-left: 5px;
}
.utb.wpthemeOpen {
	height: 20em !important;
}
.wpthemeThin {
	margin-top: 5px;
}
.wpthemeThin header {
	border: none;
	height: 20px;
}
.wpthemeThin header .wpthemeInner {
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JjY2VlZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiY2NlZWYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(188,206,239,1) 0%, rgba(188,206,239,0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(188,206,239,1)), color-stop(100%, rgba(188,206,239,0)));
	background: -webkit-linear-gradient(top, rgba(188,206,239,1) 0%, rgba(188,206,239,0) 100%);
	background: -o-linear-gradient(top, rgba(188,206,239,1) 0%, rgba(188,206,239,0) 100%);
	background: -ms-linear-gradient(top, rgba(188,206,239,1) 0%, rgba(188,206,239,0) 100%);
	background: linear-gradient(to bottom, rgba(188,206,239,1) 0%, rgba(188,206,239,0) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcceef', endColorstr='#00bcceef', GradientType=0 );
	height: 20px;
	display: none;
	margin: 0px;
	width: auto;
}
.wpthemeThin:hover header .wpthemeInner {
	display: block;
}
.wpthemeThin header h2 {
	display: none !important;
}
.wpthemeThin .wpthemeIcon {
	float: right;
	padding: 4px !important;
}
.wpthemeThin .appContainer {
	margin-top: 0px;
}
.wpthemeThin .wpthemeControlBody {
	margin: 0px 5px;
}
.menuContentContainer {
	float: left;
}
.menuContentContainer .menuContent {
	width: 175px;
}
.menuContentContainer .menuContent .menuImage {
	text-align: center;
	margin: 5px;
}
.menuContentContainer .menuContent .menuCopy {
	margin: 5px;
}
.modelHasChildrenIcon {
	display: none;
}
.loadingOverlay {
	background: #fff url('../images/ajax-loader.gif') no-repeat 10px 14px;
	position: absolute;
	z-index: 1001;
	display: none;
}
.pageOverlay {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
.loadingMessage {
	padding: 22px 50px;
	color: #999;
}
/* JSSOR Rotator */
.jssorSliderContainer {
	position: relative;
	margin: 0 auto;
	top: 0px;
	left: 0px;
	width: 1080px;
	height: 330px;
	overflow: hidden;
}
.jssorLoading {
	position: absolute;
	top: 0px;
	left: 0px;
}
.jssorLoadingOverlay {
	opacity: 0.7;
	position: absolute;
	display: block;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
.jssorLoadingIcon {
	position: absolute;
	display: block;
	background: url(images/loading.gif) no-repeat center center;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
.jssorSlides {
	cursor: move;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 1080px;
	height: 330px;
	overflow: hidden;
}
.jssorButton {
	position: absolute;
	bottom: 26px;
	right: 6px;
}
.jssorButton div, .jssorButton div:hover, .jssorButton .av {
	position: absolute;
	width: 19px;
	height: 19px;
	text-align: center;
	line-height: 19px;
	color: white;
	font-size: 12px;
	background: url(images/b21.png) no-repeat;
	overflow: hidden;
	cursor: pointer;
}
.jssorButton div {
	background-position: -5px -5px;
}
.jssorButton div:hover, .jssorButton .av:hover {
	background-position: -35px -5px;
}
.jssorButton .av {
	background-position: -65px -5px;
}
.jssorButton .dn, .jssorButton .dn:hover {
	background-position: -95px -5px;
}
.jssorArrowL, .jssorArrowR {
	display: block;
	position: absolute;
	width: 55px;
	height: 60px;
	cursor: pointer;
	background: url(images/rotatorArrows.png) center center no-repeat;
	overflow: hidden;
}
.jssorArrowL {
	background-position: -3px -31px;
}
.jssorArrowR {
	background-position: -63px -31px;
}
.jssorArrowL:hover {
	background-position: -123px -31px;
}
.jssorArrowR:hover {
	background-position: -183px -31px;
}
.jssorArrowL.jssorArrowLdn {
	background-position: -243px -31px;
}
.jssorArrowR.jssorArrowRdn {
	background-position: -303px -31px;
}
.topNavBar .modelHasChildren .modelHasChildrenIcon, .topNavBar .modelHasChildrenIcon {
	display: none;
}
.wpthemeHeaderNav ul li a, .wpthemeHeaderNav ul li a:active {
	font-size: 8pt;
}
.topNavBar ul {
    font-size: 10pt;
    font-weight: bold;
    color: #FFF;
    margin: 0;
    padding: 0;
    list-style-type: none;
    /* position: relative; */
    /* right: 50%; */
    /* left: 50%; */
    /* -webkit-transform: translateX(-50%); 
    -moz-transform: translateX(-50%);*/
    /* transform: translateX(-50%); */
    /* width: 100%; */
    /* margin-left: -33%; */
}.topNavBar ul>li {
	float: left;
	font-size:.8em;
}
.topNavBar ul>li:first-child {
	background-image: none;
	padding-left: 0;
}
.topNavBar ul>li a, .topNavBar ul>li a:hover {
	font-weight: bold;
	color: $green-link;
	text-decoration: none;
	line-height: 46px;
	padding-right: 15px;
	padding-left: 15px;
	display: block;
}

.topNavBar strong {
	font-weight: bold!important;
}
.topNavBar li .subMenu-1 {
	display: none;
	z-index: 500;
	position: absolute;
}
.topNavBar li:hover .subMenu-1 {
	display: inline;
}
.topNavBar li span.subMenu-1 ul {
	float: left;
}
.topNavBar li .subMenu-1 li {
	float: none;
	background-color: transparent!important;
	padding-left: 0;
	position: relative;
}
.topNavBar li .subMenu-1 a, .topNavBar li .subMenu-1 a:hover {
	font-size: 10pt;
	color: #FFF;
	background-color: transparent!important;
	padding: 0;
	line-height: 16px;
	margin: 10px;
	margin-left: 20px;
	margin-right: 20px;
}
.topNavBar li .subMenu-1 a:hover {
	text-decoration: underline;
}
.topNavBar li ul.subMenu-2 {
	display: none;
	z-index: 500;
	float: left;
	position: absolute;
	top: -10px;
	left: 100%;
	background-color: rgba(102, 102, 102, .95);
	white-space: nowrap;
	border-left: 1px solid #999;
}
.topNavBar .subMenu-1 li:hover .subMenu-2 {
	display: inline;
}
.topNavBar li .subMenu-2 a:hover {
	text-decoration: underline;
}
#subMenuContainer .subMenu-1 {
	display: none;
	z-index: 500;
	position: absolute;
	background: rgba(10,10,10,.8);
	border: 1px solid #999;
	border-top: none;
}
#subMenuContainer .subMenu-1 ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
#subMenuContainer .subMenu-1 .wpthemeLeft, #subMenuContainer .subMenu-1 .dropNavContainer li {
	float: none;
	margin: 0px;
	position: relative;
}
#subMenuContainer .subMenu-1 .wpthemeLeft a, #subMenuContainer .subMenu-1 .dropNavContainer a {
	padding: 5px 10px;
	display: block;
	border-top: 1px solid #cecece;
	color: #fff;
}
#subMenuContainer > ul:first-child > .toplevel > a {
	border-top: none;
}
#subMenuContainer .subMenu-1 li:hover > a {
	text-decoration: underline;
	background: rgba(255,255,255,.2);
}
#subMenuContainer ul.subMenu-2 {
	display: none;
	z-index: 500;
	float: left;
	position: absolute;
	top: 0px;
	left: 95%;
	background: #efefef;
	border: 1px solid #999;
	white-space: nowrap;
}
#subMenuContainer .subMenu-1 li:hover .subMenu-2 {
	display: inline;
}
#subMenuContainer li .subMenu-2 a:hover {
	text-decoration: underline;
}
#subMenuContainer ul {
/*	display:none; */
}
#subMenuContainer .dropNavContainer > ul {
	display: block;
}
#subMenuContainer .subMenu-1 > ul {
	display: block;
}
#subMenuContainer .subMenu-1 li ul {
	/*	display:none; */
	z-index: 500;
	float: left;
	position: absolute;
	top: 0px;
	left: 95%;
	background: rgba(10,10,10,.8);
	border: 1px solid #999;
	white-space: nowrap;
	visibility: hidden;
	opacity: 0;
}
#subMenuContainer .menuContainer > ul {
	display: inline-block;
	vertical-align: top;
}
#subMenuContainer .subMenu-1 li:hover > ul {
	/*	display:block; */
	visibility: visible;
	opacity: 1;
}
.wpthemeNavListItem.hasChildren {
}
.wpthemeNavListItem.hasChildren:before {
	content: "\f054";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: .75em;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	color: #10498F;
	float: right;
	margin-top: 3px;
}
.headerBanner .wpthemeActions {
	border-bottom: 1px solid #333;
}
.headerBanner .wpthemeActions .wpthemeCommonActions {
	margin: 5px 0;
}
.wpthemeBanner ul.wpthemeCommonActions #wpthemeSignUp {
	display: none;
}
#subMenuContainer .subMenu-1.menuAlignFull {
	border-top: 1px solid #999;
}
#subMenuContainer .subMenu-1.menuAlignFull > ul > .wpthemeLeft {
	float: left;
}
#subMenuContainer .subMenu-1.menuAlignFull > ul > .wpthemeLeft > a {
	border-top: none;
}
.s2k-dojo .dijitTitlePane .dijitTitlePane {
	clear: both;
}
.s2k-dojo .dijitTitlePaneTitle {
	font-size: 1.3em;
	color: $text-color;
	background: none;
	border: none;
	border-radius: 0;
	padding: 6px 0;
	overflow: hidden;
}
.s2k-dojo .dijitTitlePane .dijitTitlePaneTextNode {
	color:inherit;
}
.s2k-dojo .featuredItemsComponent .dijitTitlePaneTitle, .s2k-dojo .newItemsComponent .dijitTitlePaneTitle {
	margin: 0;
}
.s2k-dojo .dijitTitlePane .dijitTitlePane .dijitTitlePaneTitle {
	border-right-style: none;
	border-left-style: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}
.s2k-dojo .dijitTabPane .dijitTitlePane .dijitTitlePaneTitle, .s2k-dojo .itemFooter .dijitTitlePane .dijitTitlePaneTitle, .s2k-dojo .socialBox .dijitTitlePane .dijitTitlePaneTitle, .s2k-dojo .floatBoxColumn .dijitTitlePane .dijitTitlePaneTitle {
	border-right-style: solid;
	border-left-style: solid;
}
/*
.s2k-dojo .dijitTitlePaneTitleFocus {
    margin-top: 0;
    padding-bottom: 0;
}
*/
.s2k-dojo .dijitTitlePane .dijitArrowNode {
	display: none;
}
.s2k-dojo .dijitTitlePaneTextNode {
	margin-left: 0;
	margin-right: 4px;
	vertical-align: text-top;
}
/*
.s2k-dojo .dijitTitlePane .dijitTitlePaneTextNode {
    color: #fff;
}

.s2k-dojo .dijitTitlePaneFocused .dijitTitlePaneTextNode {
    color: #fff;
}
*/
.s2k-dojo .dijitTitlePaneContentOuter {
	background: #fff;
	border: none;
}
.s2k-dojo .dijitTitlePane .dijitTitlePane .dijitTitlePaneContentOuter {
	border-right-style: none;
	border-left-style: none;
	border-bottom-style: none;
}
.s2k-dojo .dijitTabPane .dijitTitlePane .dijitTitlePaneContentOuter, .s2k-dojo .itemFooter .dijitTitlePane .dijitTitlePaneContentOuter, .s2k-dojo .socialBox .dijitTitlePane .dijitTitlePaneContentOuter, .s2k-dojo .floatBoxColumn .dijitTitlePane .dijitTitlePaneContentOuter {
	border-right-style: solid;
	border-left-style: solid;
}
.s2k-dojo .dijitTitlePaneContentInner {
	padding: 0;
}


.s2k-dojo .expand .dijitButton .dijitButtonNode, .s2k-dojo .expand .dijitDropDownButton .dijitButtonNode, .s2k-dojo .expand .dijitComboButton .dijitButtonNode, .s2k-dojo .expand .dijitToggleButton .dijitButtonNode {
	color: #f60;
}
.s2k-dojo .cellData .rightButton .dijitButton .dijitButtonNode, .s2k-dojo .cellData .rightButton .dijitDropDownButton .dijitButtonNode, .s2k-dojo .cellData .rightButton .dijitComboButton .dijitButtonNode, .s2k-dojo .cellData .rightButton .dijitToggleButton .dijitButtonNode {
	padding: 1px 4px 1px 4px;
}
.s2k-dojo .action .dijitButtonText {
	padding: 0;
}
.s2k-dojo .action .dijitToggleButton, .s2k-dojo .action .dijitButton, .s2k-dojo .action .dijitDropDownButton, .s2k-dojo .action .dijitComboButton {
	margin: 0;
}
.s2k-dojo .action .dijitButton .dijitButtonNode, .s2k-dojo .action .dijitDropDownButton .dijitButtonNode, .s2k-dojo .action .dijitComboButton .dijitButtonNode, .s2k-dojo .action .dijitToggleButton .dijitButtonNode, .s2k-dojo .moreLess .dijitButton .dijitButtonNode, .s2k-dojo .moreLess .dijitDropDownButton .dijitButtonNode, .s2k-dojo .moreLess .dijitComboButton .dijitButtonNode, .s2k-dojo .moreLess .dijitToggleButton .dijitButtonNode {
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 0;
	margin: 0;
}
.s2k-dojo .lists .dijitTabPane {
	padding: 0;
}
.s2k-dojo .gridContainer .qty .dijitTextBox, .s2k-dojo .cartBox .addToCart .dijitTextBox, .s2k-dojo .gridContainer .itemQuantity .dijitTextBox {
	width: 50px;
}
.s2k-dojo .gridContainer .lineDiscount .dijitNumberTextBox,
.s2k-dojo .buttonBar .dealDiscount .dijitNumberTextBox {
	width: 50px;
}
.s2k-dojo .expand .dijitButtonText {
	padding: 0;
	padding-left: 5px;
	padding-right: 5px;
}
.s2k-dojo .expand .dijitToggleButton, .s2k-dojo .expand .dijitButton, .s2k-dojo .expand .dijitDropDownButton, .s2k-dojo .expand .dijitComboButton {
	margin: 0;
}
.s2k-dojo .expand .dijitButton .dijitButtonNode, .s2k-dojo .expand .dijitDropDownButton .dijitButtonNode, .s2k-dojo .expand .dijitComboButton .dijitButtonNode, .s2k-dojo .expand .dijitToggleButton .dijitButtonNode {
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 2px;
	margin: 0;
}
.s2k-dojo .field.multi .dijitTextBox {
	width: 7em;
}
.s2k-dojo .itemQuantity .dijitTextBox, .s2k-dojo .cartBox .itemQuantity .dijitTextBox {
	width: 5em;
}
.s2k-dojo .quickOrderPortlet .quickOrderInput .itemNum .dijitTextBox {
	width: 8em;
}
.s2k-dojo .layoutButtons .dijitButton .dijitButtonNode, .s2k-dojo .layoutButtons .dijitDropDownButton .dijitButtonNode, .s2k-dojo .layoutButtons .dijitComboButton .dijitButtonNode, .s2k-dojo .layoutButtons .dijitToggleButton .dijitButtonNode {
	background: none;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	padding: 0;
	padding-top: 0;
}
.s2k-dojo .layoutButtons .dijitToggleButton, .s2k-dojo .layoutButtons .dijitButton, .s2k-dojo .layoutButtons .dijitDropDownButton, .dijitComboButton {
	margin: 0;
}
.s2k-dojo .layoutButtons .dijitButtonText {
	padding: 0;
}
.s2k-dojo .listSize .dijitComboBox {
	width: 50px;
}
.s2k-dojo .split2 .dijitComboBox {
	width: 7em;
}
.s2k-dojo .genList .competitorCol .dijitTextBox {
	width: 140px;
}
.s2k-dojo .genList .rankCol .dijitTextBox {
	width: 45px;
}
.s2k-dojo .genList .removeLink button {
	background: transparent;
	border: none;
	color: #c00;
	font-size: 13pt;
	font-weight: bold;
	padding: 3px;
	margin: 0;
}
.s2k-dojo .removeLink .dijitToggleButton, .s2k-dojo .removeLink .dijitButton, .s2k-dojo .removeLink .dijitDropDownButton, .s2k-dojo .removeLink .dijitComboButton {
	margin: 0;
}
.s2k-dojo .removeLink .dijitButton .dijitButtonNode, .s2k-dojo .removeLink .dijitDropDownButton .dijitButtonNode, .s2k-dojo .removeLink .dijitComboButton .dijitButtonNode, .s2k-dojo .removeLink .dijitToggleButton .dijitButtonNode {
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 2px;
	margin: 0;
}
.s2k-dojo .gridContainer .dijitComboBox {
	width: 90%;
	max-width: 15em;
}
.s2k-dojo .dijitTextBoxDisabled input {
	color: #bbb;
}
.s2k-dojo .dijitTabContainerLeft-tabs {
	width: 150px;
	border: none;
}
.s2k-dojo .dijitTabContainerLeft-dijitContentPane {
	padding: 16px;
}
.s2k-dojo .dijitTab .tabLabel {
	min-height: 16px;
}
.updated>.tabLabel {
	color: #F00;
	width: 140px;
}
.updated>.tabLabel:before {
	content: ' ';
	background-image: url(../images/disk_icon.gif);
	background-repeat: no-repeat;
	background-position: 0 0;
	height: 16px;
	width: 16px;
	overflow: hidden;
	position: relative;
	display: block;
	float: right;
}
.s2k-dojo .dijitTabPane .emptyList .dijitTitlePane .dijitTitlePaneContentOuter {
	border-bottom-style: solid!important;
}
.s2k-dojo .itemMaintenance .delete .dijitTextBox, .s2k-dojo .itemMaintenance .delete .dijitTextBox .dijitButtonNode {
	border-color: #f00;
	background-color: #fee!important;
}
.s2k-dojo .itemMaintenance .delete .dijitTextBox .dijitInputInner {
	background-color: transparent!important;
}
.s2k-dojo .dijitDialogTitleBar {
	background-color: rgba(171, 199, 237, 1);
}
.s2k-dojo .leftCol .dijitTextBox {
	width: 180px;
}
.orderingPortletView .leftCol {
	display: none;
}
/*
.s2k-dojo .orderGuide .dijitDropDownButton .dijitButtonNode {
	background: none;
	border: none;
	box-shadow: none;
	border-radius: 0;
	color: #676767;
	padding: 0;
}
*/

.buttonBar .pgmTitle {
	font-size: 1.2em;
	color: #F79737;
	margin: 5px 10px;
}
.boxes .itemResults .detailRow,
.headerBanner .wpthemeInner,
.wpthemeFooter .wpthemeInner,
.appContainer .innerAppContainer,
.wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.wptheme1Col .wpthemeCol,
.wptheme2Col .wpthemeCol,
.itemDetailLayout4 .itemDetailLeft,
.itemDetailLayout4 .itemDetailRight,
.itemDetailLayout4 .itemDetailFull,
.itemDetailLayout4 .rightColumn,
.loginPortlet .floatBoxColumn,
.appContainer .mainCol,
.mobileSearchContainer .wpthemeInner {
	transition: $transition;
}
.appContainer .leftCol {
	width: 200px;
	float: left;
	margin-right: 6px;
}
.appContainer.programOrderView .mainCol, .appContainer.categoryListing .mainCol, .appContainer.productItemListing .mainCol, .appContainer.searchResults .mainCol, .appContainer.manufacturerItems .mainCol, .appContainer.orderFromHistory .mainCol {
	width: 874px;
	float: left;
}
.appContainer.programOrderDetail .mainCol {
	float: none;
	clear: both;
	width: 100%;
}
.appContainer.categoryListing .innerAppContainer.noLeftContent .mainCol, .appContainer.productItemListing .innerAppContainer.noLeftContent .mainCol, .appContainer.searchResults .innerAppContainer.noLeftContent .mainCol, .appContainer.manufacturerItems .innerAppContainer.noLeftContent .mainCol {
	width: 100%;
}
 @media screen and (max-width: 1100px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 960px;
}
.appContainer.programOrderView .mainCol, .appContainer.categoryListing .mainCol, .appContainer.productItemListing .mainCol, .appContainer.searchResults .mainCol, .appContainer.manufacturerItems .mainCol, .appContainer.orderFromHistory .mainCol {
	width: 754px;
}
}
 @media screen and (max-width: 980px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 748px;
}
.appContainer.programOrderView .mainCol, .appContainer.categoryListing .mainCol, .appContainer.productItemListing .mainCol, .appContainer.searchResults .mainCol, .appContainer.manufacturerItems .mainCol, .appContainer.orderFromHistory .mainCol {
	width: 542px;
}
}
@media screen and (max-width: 768px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 583px;
}
.appContainer.programOrderView .mainCol, .appContainer.categoryListing .mainCol, .appContainer.productItemListing .mainCol, .appContainer.searchResults .mainCol, .appContainer.manufacturerItems .mainCol, .appContainer.orderFromHistory .mainCol {
	width: 377px;
}

.sellPoints {
	display: none
}

}
@media screen and (max-width: 603px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 460px;
}
.appContainer .leftCol {
	width: 100%;
	margin-right: 0;
}
.appContainer.programOrderView .mainCol, .appContainer.categoryListing .mainCol, .appContainer.productItemListing .mainCol, .appContainer.searchResults .mainCol, .appContainer.manufacturerItems .mainCol, .appContainer.orderFromHistory .mainCol {
	width: 100%;
}
.wpthemeLogo {
	margin-bottom: 35px;
}
}
@media screen and (max-width: 480px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 300px;
}
.outerContainer .innerContainer .wpthemeInner .leftCol, .appContainer .mainCol, .outerContainer .innerContainer .wpthemeInner .rightCol, .appContainer .rightCol {
	width: 100%;
	float: none;
}
}
 @media screen and (max-width: 360px) {
.headerBanner .wpthemeInner, .wpthemeFooter .wpthemeInner, .appContainer .innerAppContainer, .wpthemeCrumbTrailContainer .wpthemeCrumbTrail,
.mobileSearchContainer .wpthemeInner  {
	width: 280px;
}
.bannerContainers .slogan {
	display: none!important;
}
.bannerContainers .bannerSearch {
	position: relative!important;
}
.commerceCart .shoppingCartBar {
	display: none;
}
.wpthemeLogoMobile .logo {
	margin: 0px auto;
}
.wpthemeNavToggleBtn {
	padding: 0px 0 10px 0px;
	float: none;
	clear: both;
}
#wpthemeLogout, #wpthemeLogin {
	padding: 0px;
	width: 100%;
}
.wpthemeCommonActions .userName {
	padding: 5px 10px;
}
ul.wpthemeCommonActions > li {
	display: block;
	float: left;
	margin: 0;
	color: #f60;
	padding: 0px 3px;
	vertical-align: middle;
	font-size: 11px;
}

}
/******* Terry's css additions *******/

* {
	box-sizing: border-box;
}
.row::after {
	content: "";
	clear: both;
	display: table;
}
[class*="col-"] {
 float: left;
 padding: 5px 2px;
}
html {
	font-family: "Lucida Sans", sans-serif;
}
#contContainer {
	width: 100%;
}
.heroImg {
	width: 100%;
	text-align: center;
}
.heroImg img {
	width: 100%;
}
.wcmContent {
	width: 80%;
	margin: 0px auto;
}
.wcmLeft {
	float: left;
	text-align: left;
	padding: 4px 0px;
	position: relative;
}
.wcmLogo {
	width: 100%;
	margin: 2px 2px;
}
.wcmCopy {
	width: 100%;
}
.wcmCopyBottom {
	clear: both;
	display: block;
}
.wcmRight {
	display: block;
	float: right;
	text-align: center;
	padding: 0px 0px;
}
.imgGroup {
	width: 100%;
}
.imgGroup img {
	width: 100%;
	margin: 1px 2px;
}
.img1 {
}
.img2 {
}
.img3 {
}
.img4 {
}
.img5 {
}
div.sellPoints div div {
	background-color: #dbece6;
	padding: 3px;
	text-align: center;
	min-height: 116px;
	max-height: 200px;
}
div.sellPoints div {
	padding: 6px;
	display: block;
}
div.sellPoints div div h2 {
	display: block;
	font-size: 1.5em;
	-webkit-margin-before: 0.83em;
	-webkit-margin-after: 0.83em;
	-webkit-margin-start: 0px;
	-webkit-margin-end: 0px;
	font-weight: bold;
}
.grayBar {
	background-color: #999;
	color: #fff;
	display: inline-block;
	width: 100%;
	padding: 0px 0px 0px 10px;
	max-height: 40px;
}
.sectCats div {
	margin-top: 0px;
	margin-bottom: 5px;
	padding: 0px 10px 0px 0px;
}
.sectCats div:nth-child(3) {
	padding: 0px 0px 0px 0px;
}
.sectCats div:nth-child(6) {
	padding: 0px 0px 0px 0px;
}
.sectCats div img {
	width: 100%
}
.sectExc div img {
	width: 75%
}
.sectExc a {
	margin: 0px 0px 19px 0px;
	display: block;
}
.sectExc {
	display: table;
	margin: 0px;
	padding: 3px;
	width: 100%;
	text-align: center;
	background: gray; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(white, gray); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(white, gray); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(white, gray); /* For Firefox 3.6 to 15 */
	background: linear-gradient(white, gray); /* Standard syntax */
}
.hmSecTitles {
	color: #096;
	text-transform: uppercase;
	font-weight: bold;
	clear: both
}
.d-none {
	display: none
}
.mainCol .featuredItemsComponent {
	margin-bottom: 6px;
	display: none !important;
}
.bannerContainers .slogan {
	font-size: 01.15em;
	left: 125px;
}
.themeSearchBar .field .dijitTextBox {
//	border-radius: 0px;
}
.wpthemeFooter {
	background-color: #666;
}
.wpthemeFooter .wpthemeInner .footer-container {
	border-top: 0px solid #fff;
}
.footer, .footer a {
	color: #fff
}
.footer h4 {
	padding-bottom: 2px;
	    font-family: crete round;
}
div.footer div.footer-col div.footer-col-content {
	padding: 0px;
}
.subFootCont {
	width: 100%;
	background-color: #fff;
	padding: 30px;
	text-align: center;
	color: #666;
}
.subFootCont a, .subFootCont span {
	color: #f60;
	text-transform: uppercase;
	padding: 0px 10px;
}
.subFootCont span {
	display: block;
	margin-bottom: 6px
}
.wpthemeLeft a[href="https://www.facebook.com/arettsales"]::before {
	content: url(../images/facebook2.png);
}
.wpthemeLeft a[href="https://twitter.com/arettsales"]::before {
	content: url(../images/twitter.png);
}
.wpthemeLeft a[href="https://www.youtube.com/greensmith"]::before {
	content: url(../images/youtube_Logo.png);
}
.wpthemeLeft a[href="https://plus.google.com/111544027197234329834"]::before {
	content: url(../images/google.png);
}
.wpthemeLeft a[href="https://www.linkedin.com/company/arett-sales"]::before {
	content: url(../images/linkedin.png);
}
.wpthemeLeft a[href="https://www.pinterest.com/arettsales/"]::before {
	content: url(../images/pinterest.png);
}
.wpthemeLeft a[href="/wps/portal/c/stay-connected/Arett-mobile-manager"]::before {
	content: url(../images/arett-icon.png);
}
.text-center {
	text-align: center
}
.homeContent {
	display: none
}
.hotDealContainer {
	max-height: 210px;
}
div.sellPoints div div h2 {
	padding-bottom: 0px!important;
}
div.hotDealScroller .dijitButtonNode {
	display: none!important;
}
div.hotDealScroller .itemDetails {
	background: none!important;
}
div.hotDealScroller .itemQuantity {
	display: none!important;
}
div.hotDealScroller .unitMeasure {
	display: none!important;
}
div.hotDealScroller .itemCartOptions {
	height: 0px!important;
}
div.hotDealScroller .itemDescription {
	height: auto!important;
}
div.hotDealScroller .itemDescription a {
	color: #000!important;
	word-wrap: break-word!important;
}
div.hotDealScroller .detailBorder {
	text-align: center!important;
}
div.imgGroup  div   {padding-top:0px!important; padding-bottom:2px!important; padding-right: 10px!important;  }

div.hotDealScroller .thePrice {
	color: #f60!important;
	font-size: .9em!important;
}
/*.wptheme2ColLeft.s2kLayouts>.mainCol {
    float: left;
    width: 130%!important;}*/
	
	
.wpthemeBanner .wpthemeMobileBorder {
	border: none;
}
.wpthemeLogoMobile .logo {
	width: 100%!important;
   /* padding: 10px;*/
    margin: 0px auto;
}
/*.wpthemeBannerInner {
	background: #b4d7c8 !important;
}*/
/*.wpthemeNavToggleBtn {
    padding: 15px 0 10px;
    float: right;
    clear: both;
}*/

.wpthemeLogoMobile {
	/*width: 46px;
	margin-left: 15px;*/
    position: relative;
    text-align: center;
    padding: 19px;
}


.hotDealScroller {
	width: 100% !important;
}
.hotDealContainer .scrollItem {
	width: 20% !important;
}
.flyBox, .flyBoxAlert {
	z-index: 9999;
}
.flyBox .fbContent {
	border: 3px solid #ccc;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	width: 90%;
	height: 70%;
}
/******* end Terry's css additions and overides *******/

.wpthemeBannerPrimaryNavigation .wpthemeNavContainer1 {
    float: none !important;
}
nav.wpthemePrimaryNav.wpthemeLeft {
    display: table;
    margin: auto;
    float: none;
}
li#wpthemeActionsMenu {
    display: none;
}


/**media queries for columns **/
/* For mobile phones: */
[class*="col-"] {
 width: 100%;
}
 @media only screen and (min-width: 600px) {
/* For tablets: */
.col-m-1 {
	width: 8.33%;
}
.col-m-2 {
	width: 16.66%;
}
.col-m-3 {
	width: 25%;
}
.col-m-4 {
	width: 33.33%;
}
.col-m-5 {
	width: 41.66%;
}
.col-m-6 {
	width: 50%;
}
.col-m-7 {
	width: 58.33%;
}
.col-m-8 {
	width: 66.66%;
}
.col-m-9 {
	width: 75%;
}
.col-m-10 {
	width: 83.33%;
}
.col-m-11 {
	width: 91.66%;
}
.col-m-12 {
	width: 100%;
}
.wcmCopyBottom p {
	width: 100%;
	text-align: left;
}
.wpthemeFooter .wpthemeInner .footer-col {
	width: 50%;
	display: inline-block;
	margin-right: -4px;
	vertical-align: top;
}
.bannerContainers .slogan {
	/*display: none;*/
}
.mainCol .featuredItemsComponent {
	margin-bottom: 6px;
	display: block !important;
}
/*.bannerContainers .bannerCart {
    position: absolute !important;
    bottom: 40px;
    right: 0;
}*/



}
 @media only screen and (min-width: 768px) {
/* For desktop: */
.col-1 {
	width: 8.33%;
}
.col-2 {
	width: 16.66%;
}
.col-3 {
	width: 25%;
}
.col-4 {
	width: 33.33%;
}
.col-5 {
	width: 41.66%;
}
.col-6 {
	width: 50%;
}
.col-7 {
	width: 58.33%;
}
.col-8 {
	width: 66.66%;
}
.col-9 {
	width: 75%;
}
.col-10 {
	width: 83.33%;
}
.col-11 {
	width: 91.66%;
}
.col-12 {
	width: 100%;
}
.wcmCopyBottom p {
	width: 70%;
	text-align: left;
}
.s-title {
	display: none
}
.wpthemeFooter .wpthemeInner .footer-col {
	width: 14%;
}
.bannerContainers .slogan {
	
   
    left: 200px !important;
  
}

.mainCol .featuredItemsComponent {
	margin-bottom: 6px;
	display: block !important;
}
}


 @media screen and (max-width: 1099px) and (min-width: 499px) {
.wptheme2ColRight.s2kLayouts > .mainCol, .wptheme2ColRightAlt.s2kLayouts > .mainCol, .wptheme2ColLeft.s2kLayouts > .mainCol, .wptheme2ColLeftAlt.s2kLayouts > .mainCol {
	width: 100%;
	float: none;
}
.wptheme2ColLeft.s2kLayouts > .rightCol {
	/* float: left; */
	clear: both;
	width: 100%;
}
div.imgGroup div {
	width: 33%;
	vertical-align: middle;
	height: auto;
}

}


@media screen and (max-width:768px) {
.bannerContainers .slogan {
   display:none;
}

wpthemeBanner .wpthemeBannerInner {
     background-color: #b4d7c8!important;
}


}
.joinList{ 
     position:relative;
     top:20px; 
     right:0
}

.buttonSignUp {padding:10px 5px; background-color:#F90; color:#fff; display:block; width: 80%;
    border: 1px solid; border-radius: 5px}
	
	


.wpthemeMobileNav > ul.wpthemeExpandNav > .wpthemeNavListItem, .wpthemeMobileNav.wpthemeMobileSide:nth-of-type(3) {
    background-color: #78a290;
}

.listView .detailRow .price .thePrice {
   /* font-weight: bold;
    margin-right: 5px;
    display: inline-block;*/
    font-size: .86em !important;
  /*  color: #69bf30;*/
}

.no-left-column .appContainer.productItemListing .mainCol {
    width: 100%;
}